<template>
  <div>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-10">
        <h2>Compras</h2>
        <ol class="breadcrumb">
          <li>
            General
          </li>
          <li>
            <strong>Configuración</strong>
          </li>
        </ol>
      </div>
    </div>

    <div class="wrapper wrapper-content">
      <div class="row">
        <div class="col-lg-12">
          <cmp-secciones seccion="tablero"></cmp-secciones>
        </div>
      </div>
   
      <br />
      
      <div class="ibox">
        <div class="ibox-title">
          <h5>Configuración para notificación y seguimiento de solicitudes</h5>
          <div class="ibox-tools">
            <a @click="cerrar()">
              <i class="fa fa-close"></i>
            </a>
          </div>
        </div>
        <div class="ibox-content">
          <div class="table-responsive">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div class="checkbox checkbox-success checkbox-circle">
                      <input id="dia" type="checkbox">
                      <label for="dia"></label>
                    </div>
                  </td>
                  <td>
                    Los requerimientos y/o cotizaciones que tengan un monto de&nbsp;
                  </td>
                  <td style="width:120px">
                    <div class="input-group">
                      <span class="input-group-addon">
                        $
                      </span>
                      <input type="number" class="form-control" placeholder="0"/>
                    </div>
                  </td>
                  <td>
                    &nbsp;
                    hasta
                    &nbsp;
                  </td>
                  <td style="width:120px">
                    <div class="input-group">
                      <span class="input-group-addon">
                        $
                      </span>
                      <input type="number" class="form-control" placeholder="2000"/>
                    </div>
                  </td>
                  <td>
                    &nbsp;
                    Serán atendidos por
                    &nbsp;
                  </td>
                  <td>
                    <select class="form-control">
                      <option>Seleccionar rol de usuarios</option>
                      <option>Administrador</option>
                      <option>Vendedor</option>
                      <option>Instalador</option>
                      <option>Usuario</option>
                    </select>
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td style="width:190px">
                    <div class="input-group">
                      <div class="input-group-btn">
                        <button class="btn btn-default">
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                      <input class="form-control" placeholder="Agregar usuario/s"/>
                    </div>
                  </td>
                  <td>
                    &nbsp;
                    para aprobar, rechazar, posponer o cancelar.
                  </td>
                </tr>

                <tr>
                  <td>
                    <div class="checkbox checkbox-success checkbox-circle">
                      <input id="dia" type="checkbox">
                      <label for="dia"></label>
                    </div>
                  </td>
                  <td>
                    Los requerimientos y/o cotizaciones que tengan un monto de&nbsp;
                  </td>
                  <td style="width:120px">
                    <div class="input-group">
                      <span class="input-group-addon">
                        $
                      </span>
                      <input type="number" class="form-control" placeholder="0"/>
                    </div>
                  </td>
                  <td>
                    &nbsp;
                    hasta
                    &nbsp;
                  </td>
                  <td style="width:120px">
                    <div class="input-group">
                      <span class="input-group-addon">
                        $
                      </span>
                      <input type="number" class="form-control" placeholder="2000"/>
                    </div>
                  </td>
                  <td>
                    &nbsp;
                    Serán atendidos por
                    &nbsp;
                  </td>
                  <td>
                    <select class="form-control">
                      <option>Seleccionar rol de usuarios</option>
                      <option>Administrador</option>
                      <option>Vendedor</option>
                      <option>Instalador</option>
                      <option>Usuario</option>
                    </select>
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td  style="width:190px">
                    <div class="input-group">
                      <div class="input-group-btn">
                        <button class="btn btn-default">
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                      <input class="form-control" placeholder="Agregar usuario/s"/>
                    </div>
                  </td>
                  <td>
                    &nbsp;
                    para aprobar, rechazar, posponer o cancelar.
                  </td>
                </tr>

                <tr>
                  <td>
                    <div class="checkbox checkbox-success checkbox-circle">
                      <input id="dia" type="checkbox">
                      <label for="dia"></label>
                    </div>
                  </td>
                  <td>
                    Los requerimientos y/o cotizaciones que tengan un monto de&nbsp;
                  </td>
                  <td style="width:120px">
                    <div class="input-group">
                      <span class="input-group-addon">
                        $
                      </span>
                      <input type="number" class="form-control" placeholder="0"/>
                    </div>
                  </td>
                  <td>
                    &nbsp;
                    hasta
                    &nbsp;
                  </td>
                  <td style="width:120px">
                    <div class="input-group">
                      <span class="input-group-addon">
                        $
                      </span>
                      <input type="number" class="form-control" placeholder="2000"/>
                    </div>
                  </td>
                  <td>
                    &nbsp;
                    Serán atendidos por
                    &nbsp;
                  </td>
                  <td>
                    <select class="form-control">
                      <option>Seleccionar rol de usuarios</option>
                      <option>Administrador</option>
                      <option>Vendedor</option>
                      <option>Instalador</option>
                      <option>Usuario</option>
                    </select>
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td  style="width:190px">
                    <div class="input-group">
                      <div class="input-group-btn">
                        <button class="btn btn-default">
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                      <input class="form-control" placeholder="Agregar usuario/s"/>
                    </div>
                  </td>
                  <td>
                    &nbsp;
                    para aprobar, rechazar, posponer o cancelar.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="text-right">
        <button @click="cerrar()" class="btn btn-default">
            <i class="fa fa-angle-left"></i> Atras
        </button>
        &nbsp;
        <button
            type="button"
            class="btn btn-primary"
            @click="actualizar()"
        >
            <i class="fa fa-check"></i>
            Actualizar
        </button>
        </div>
    </div>
  </div>  
</template>

<script>
import CmpSecciones from './CmpSecciones.vue'
var accounting = require('accounting/accounting.js')
import moment from 'moment'

export default {
  components: {
    CmpSecciones,
  },
  data() {
    return {
      configuracion:[]
    }
  },
  created() {
    var self = this
  },
  methods: {
    actualizar: function() {
      iu.msg.success('Se actualizo correctamente')
    },

    atras: function() {
      this.$router.go(-1)
    },

    cerrar: function() {
      this.atras()
    }
  }
}
</script>

<style></style>
